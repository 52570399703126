
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

import { ClaimReview, Partner } from '@/types';

import * as FactcheckServices from '@/services/FactCheckService'

@Component
export default class ClaimReviewForm extends Vue {
    @VModel() claimReview!: ClaimReview;
    @Prop({ required: true }) prefilledFields!: Array<{ field: string, from: string }>

    partners: Partner[] = []

    async mounted() {
        this.partners = await FactcheckServices.GetPartners()
    }

    menu = false;
    activePicker = '';

    countries = [
        {
            name: 'België',
            code: 'BE'
        },
        {
            name: 'Nederland',
            code: 'NL'
        },
        {
            name: 'Duitsland',
            code: 'DE'
        }
    ]
}
